import { baseAxios } from "../utils/axios";
import { urls } from "./configs/urls";
import {updateUser} from "../redux/user";

const PATH = urls.summaryInformation;

class UserService {
  async summaryInformation() {
    const response = await baseAxios.get(PATH);
    return response.data;
  }
  updateUser(id, data) {
    return async dispatch => {
      return baseAxios.patch(`${urls.users}${id}`, data)
        .then(res => dispatch(updateUser(res.data)))
    }
  }
  async getGoogleProfile(token) {
    try {
      const res = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      return await res.json()
    } catch (error) {
      throw error
    }
  }
}

export default new UserService();
