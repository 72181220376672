import PropTypes from "prop-types";

const foodProp = {
  co2_captured: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  image_url: PropTypes.string,
  interactions: PropTypes.number.isRequired,
  item_weight: PropTypes.number.isRequired,
  labelling: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  subcategory: PropTypes.number.isRequired,
  subcategory_name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  user: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  water_captured: PropTypes.number.isRequired,
};

export default foodProp;
