import { baseAxios } from "../utils/axios";

const URLS = {
  sendMagicLink: 'api/auth/magic-login/',
  verifyMagicLink: 'api/auth/magic-login/verify/',
  verifyOtpLink: 'api/auth/verify-otp/',
  sendOtpLink: 'api/auth/send-otp/',
}

class Auth {
  constructor(apiAgent) {
    this.apiAgent = apiAgent
  }
  async createMagicLink(email) {
    return this.apiAgent.post(URLS.sendMagicLink, { email })
  }

  async verifyMagicToken(token) {
    return this.apiAgent.post(URLS.verifyMagicLink, { sesame: token })
  }

  verifyOtp(code, email) {
    return this.apiAgent.post(URLS.verifyOtpLink, { otp: code, email })
  }

  sendOtp(phone, email) {
    return this.apiAgent.post(URLS.sendOtpLink, { phone_number: phone, ...(email ? { email } : {}) })
  }
}

export default new Auth(baseAxios)
