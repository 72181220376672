import {baseAxios, errorMessage} from "../../../utils/axios";
import {getErrorMessage} from "source-map-explorer/lib/app-error";
import {trimQuery} from "../../../utils/data/trimQuery";

const eventUpdate = async (
  unlabelledEvent,
  user,
  select,
  food,
  setDevice,
  clearingValues,
  enqueueSnackbar,
  selectedImage,
  backupImages,
) => {
  if (!unlabelledEvent) return;

  const data = {
    status: select === "Escalate" ? "ESCALATED" : "SUCCESSFUL",
    labelled_at: new Date().toISOString(),
    food_description: food.length ? food[0].id : null,
    trimmings: select === "Trimmings",
    user: user.id,
  };
  if (selectedImage && backupImages.length !== 0) {
    data["image_url"] = trimQuery(selectedImage);
    data["backup_image_url"] = backupImages;
  }

  const url = `/api/labelling/${unlabelledEvent.id}/`;

  try {
    const { status } = await baseAxios.patch(url, data);

    if (status === 204) {
      setDevice(null);
    } else {
      setDevice((prevDevice) => {
        return { ...prevDevice, irl: prevDevice.irl - 1 };
      });
    }
  } catch (error) {
    const imageError = errorMessage(error, ['backup_image_url', 'image_url'])
    if (imageError) {
      enqueueSnackbar(`${imageError}. Try refresh browser or contact support`, {
        variant: "error",
      });
      return;
    }
    enqueueSnackbar("Oops, something went wrong.", {
      variant: "error",
    });
  } finally {
    clearingValues();
  }
};

export default eventUpdate;
