import React, {useEffect, useRef, useState} from 'react';
import FormLayout from "../../components/auth/FormLayout";
import {Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import {COLORS} from "../../constants/base";
import {useFormik} from "formik";
import useAuth from "../../hooks/useAuth";
import {useNavigate} from "react-router-dom";

const Input = styled.input`
    width: 50px;
    height: 50px;
    border: 1px solid ${COLORS.gray_light};
    border-radius: 8px;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
`

const withMfa = Component => props => {
  const { mfaPhone } = useAuth()
  const navigate = useNavigate();

  useEffect(() => {
    if(!mfaPhone) {
      navigate('/')
    }
  }, [mfaPhone]);

  if(!mfaPhone) {
    return null
  }
  return <Component {...props} />
}

const isNumber = value => /^\d$/.test(value)
const isValidCode = value => /^\d{4}$/.test(value)
const CODE_LENGTH = 4

const Timer = ({ time, onStop }) => {
  const [targetTime, setTargetTime] = useState(time)

  useEffect(() => {
    const timerId = setInterval(() => {
      setTargetTime(t => {
        if (t <= 0) {
          clearInterval(timerId)
        }
        return Math.max(t - 1, 0)
      })
    }, [1000])
    return () => {
      clearInterval(timerId)
    }
  }, []);

  useEffect(() => {
    if (!targetTime) {
      onStop && onStop()
    }
  }, [targetTime])

  return (
    <Typography>Resend code in 00:{targetTime.toString().padStart(2, '0')}</Typography>
  )
}

const ResendCode = () => {
  const [waitTime, setWaitTime] = useState(0)
  const { sendOtp } = useAuth()

  const resendCode = () => {
    sendOtp()
      .then(() => setWaitTime(60))
  }

  const clearWaitTime = () => setWaitTime(0)

  return (
    <Stack direction="row" justifyContent="center" spacing={2}>
      {
        !!waitTime
          ? <Timer time={waitTime} onStop={clearWaitTime} />
          : (
            <>
              <Typography>Didn't receive the code? </Typography>
              <Button
                sx={{
                  color: COLORS.darkBlue2,
                  fontSize: "13px",
                  fontWeight: 400,
                  padding: 0,
                  marginBottom: "2px",
                  textDecoration: 'underline',
                  display: 'inline'
                }}
                onClick={resendCode}
              >
                Click here to try again
              </Button>
            </>
          )
      }
    </Stack>
  )
}

const CodeInput = ({ onChange, value, length }) => {
  const formRef = useRef(null);
  const initValue = Array(length).fill(0)
    .reduce((acc, item, index) => ({...acc, [index]: value?.[index] || ''}), {})

  const { values, setFieldValue } = useFormik({
    initialValues: initValue,
  })
  const changeValue = key => event => {
    const value = event.target.value;
    if (!value || isNumber(value)) {
      setFieldValue(key, event.target.value)
      if (formRef.current) {
        const nextKey = !value ? key - 1 : key + 1
        formRef.current[nextKey]?.focus()
      }
    }
  }

  const handlePaste = event => {
    event.preventDefault()
    const code = event.clipboardData.getData('text').trim()
    if (isValidCode(code)) {
      code.split('').forEach((num, i) => setFieldValue(i, num))
    }
  }

  useEffect(() => {
    const value = Object.values(values).join('')
    if (onChange) {
      onChange(value)
    }
  }, [values])

  return (
    <form ref={formRef}>
      <Stack direction="row" spacing={2} justifyContent="center">
        {Array(length).fill(0).map((_, i) => (
          <Input onPaste={handlePaste} onChange={changeValue(i)} value={values[i]} name={i} key={i} />
        ))}
      </Stack>
    </form>
  )
}

const VerificationCode = () => {
  const [code, setCode] = useState('')
  const { verifyOtp, mfaPhone } = useAuth()
  const phoneToDisplay = `${mfaPhone.slice(0, 6)}***${mfaPhone.slice(-3)}`

  const handleVerify = () => verifyOtp(code)

  return (
    <FormLayout
      title='Verification'
      subtitle={`You have been sent a mobile code to ${phoneToDisplay}. Enter here...`}
    >
      <form>
        <Stack spacing={4}>
          <CodeInput length={CODE_LENGTH} onChange={setCode} />
          <Button
            variant="contained"
            color="secondary"
            disabled={code.length !== CODE_LENGTH}
            onClick={handleVerify}
          >
            Continue
          </Button>
         <ResendCode />
        </Stack>
      </form>
    </FormLayout>
  );
};

export default withMfa(VerificationCode);