import React, {useState} from 'react';
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import CodeInput from "./CodeInput";
import ResendCode from './Resend'

const VerifyCode = ({ onVerify, onError, codeLength = 4, resend }) => {
  const [code, setCode] = useState('');
  const handleVerify = async () => {
    try {
     await onVerify(code)
    } catch (e) {
      onError && onError(e)
    }
  }

  return (
    <Stack spacing={4}>
      <CodeInput length={codeLength} onChange={setCode}/>
      <Button
        variant="contained"
        color="secondary"
        disabled={code.length !== codeLength}
        onClick={handleVerify}
      >
        Continue
      </Button>
      <ResendCode action={resend} />
    </Stack>
  );
};

export default VerifyCode;