import React from "react";
import {Box, Button, FormControlLabel, Stack} from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import discardEvent from "./axios/discardEvent";
import incorrectEvent from "./axios/incorrectEvent";
import correctEvent from "./axios/correctEvent";
import { selectUser } from "../../redux/user";
import useKeyPress from "../../hooks/useKeyPress";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import {useModal} from "mui-modal-provider";
import SimpleDialog from "../dialogs/SimpleDialog";
import useIsMobile, { useIsMiniMobile } from "../../hooks/useIsMobile";
import ConfirmModal from "../feed/ConfirmModal";
import {discardedStatus} from "../feed/configs/constants";
import {CustomCheckbox} from "../styled/GlobalStyled";
import {getEventImages} from "../../utils/formaters/getEventImages";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "5px 0",
}));

const Buttons = styled(Box)(() => ({
  display: "flex",
}));

const WasteButton = styled(Button)(() => ({
  maxWidth: "240px",
  width: "100%",
  height: "50px",
  margin: "5px",
}));

function ButtonSection({
  event,
  reset,
  setLeftTime,
  loadingEvent,
  setLoadingEvent,
  trimmings,
  setTrimmings,
  imageReady,
  selectedImage,
}) {
  const user = useSelector(selectUser);
  const mixpanelTrack = useMixpanelTrack();
  const { showModal } = useModal()
  const isMobile = useIsMobile()
  const isSmallMobile = useIsMiniMobile()

  const handleDiscard = async (descriptionId) => {
    if (event) {
      setLoadingEvent(true);
      await discardEvent({
        eventId: event.id,
        descriptionId,
        user,
        setLeftTime,
        reset,
        trimmings,
      });
    }
    mixpanelTrack("Verification - Discard - Click");
  };
  const handleIncorrect = async (descriptionId) => {
    if (event) {
      setLoadingEvent(true);
      await incorrectEvent({
        eventId: event.id,
        descriptionId,
        user,
        setLeftTime,
        reset,
        trimmings,
        ...getEventImages(event, selectedImage)
      });
    }
    mixpanelTrack("Verification - Incorrect - Click");
  };
  const handleCorrect = async () => {
    if (event) {
      setLoadingEvent(true);
      await correctEvent({
        eventId: event.id,
        user,
        setLeftTime,
        reset,
        trimmings,
        ...getEventImages(event, selectedImage)
      });
    }
    mixpanelTrack("Verification - Correct - Click");
  };

  const handleSubmit = (cb, status) => () => {
    const targetModal =  showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: <ConfirmModal site={event.site_id} status={status} onSubmit={cb} close={() => targetModal.hide()} />,
    })
  }

  const withEventDisabled = (cb) => {
    if (loadingEvent || !imageReady) return () => {};
    return cb
  }

  useKeyPress(["1"], withEventDisabled(handleSubmit(handleDiscard, discardedStatus)));
  useKeyPress(["2"], withEventDisabled(handleSubmit(handleIncorrect)));
  useKeyPress(["3"], withEventDisabled(handleCorrect));

  const handleTrimmingsChange = event => {
    setTrimmings(event.target.checked);
  }

  return (
    <Wrapper>
      {!isMobile && <Box sx={{ width: "51%" }} />}
      <FormControlLabel
        checked={trimmings}
        control={
          <CustomCheckbox
            sx={{ padding: "0 11px" }}
            name="trimmings"
            onChange={handleTrimmingsChange}
          />
        }
        label="Trimmings"
      />
      <Stack direction={isSmallMobile ? 'column' : 'row'} sx={{ flex: 1 }}>
        <WasteButton
          data-testid="discard-btn"
          disabled={loadingEvent || !imageReady}
          color="error"
          variant="contained"
          onClick={handleSubmit(handleDiscard, discardedStatus)}
        >
          Discard
        </WasteButton>
        <WasteButton
          data-testid="incorrect-btn"
          disabled={loadingEvent || !imageReady}
          color="warning"
          variant="contained"
          onClick={handleSubmit((handleIncorrect))}
        >
          Incorrect
        </WasteButton>
        <WasteButton
          data-testid="correct-btn"
          disabled={loadingEvent || !imageReady}
          color="success"
          variant="contained"
          onClick={handleCorrect}
        >
          Correct
        </WasteButton>
      </Stack>
    </Wrapper>
  );
}

ButtonSection.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  }),
  reset: PropTypes.func.isRequired,
  setLeftTime: PropTypes.func.isRequired,
  loadingEvent: PropTypes.bool.isRequired,
  setLoadingEvent: PropTypes.func.isRequired,
};

ButtonSection.defaultProps = {
  event: null,
};

export default ButtonSection;
