import React, {useCallback} from 'react';
import BaseModal from "../../layouts/Modal";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../redux/user";
import MuiPhone from "../inputs/MuiPhone";
import Button from "@mui/material/Button";
import userService from "../../services/UserService";
import authService from "../../services/Auth";
import * as Yup from "yup";
import { isPhoneValid } from "../../utils/validation/isPhoneValid";
import {useFormik} from "formik";
import VerifyCode from "../mfa/VerifyCode";
import {Alert} from "@mui/material";

const validation = Yup.object().shape({
  phone: Yup.string().test('is-phone-valid', 'Phone number is invalid', isPhoneValid).required('Required field'),
})

const VERIFY_CODE = 'Verify received code'
const VERIFY_PHONE = 'Verify phone number'

const EnableMfaModal = ({ close }) => {
  const { phone_number, id, email } = useSelector(selectUser)
  const [otpSentTo, setOtpSentTo] = React.useState('');
  const dispatch = useDispatch();
  const [error, setError] = React.useState(null);
  const enable2fa = async (code) => {
    return dispatch(userService.updateUser(id, { is_mfa_enabled: true, phone_number: otpSentTo, otp: code }))
      .then(() => close())
  }
  const sendOtp = ({ phone }) => authService.sendOtp(phone, email)
    .then(() => setOtpSentTo(phone))

  const resendCode = useCallback(() => authService.sendOtp(otpSentTo, email), [otpSentTo])

  const handleOtpError = () => setError(true)

  const { values, setFieldValue, errors, handleSubmit, touched }  = useFormik({
    initialValues: {
      phone: phone_number || ""
    },
    validationSchema: validation,
    onSubmit: sendOtp,
  })
  return (
    <BaseModal title={otpSentTo ? VERIFY_CODE : VERIFY_PHONE}>
      {error && <Alert severity="warning">Invalid or expired OTP.</Alert>}
      {otpSentTo
        ? <VerifyCode onVerify={enable2fa} onError={handleOtpError} resend={resendCode} />
        : (
          <>
            <MuiPhone
              name="phone"
              value={values.phone}
              onChange={data => setFieldValue('phone', data)}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
            >
              Send verification code
            </Button>
          </>
      )
      }
    </BaseModal>
  );
};

export default EnableMfaModal;