import { BlobServiceClient } from "@azure/storage-blob";
import { appConfig } from "../config";
import {baseAxios} from "./axios";

const createBlobInContainer = async (blobClient, file) => {
  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  const response = await blobClient.uploadData(file, options);

  try {
    if (response._response.status === 201) {
      return blobClient.url.split("?")[0];
    }
  } catch (error) {
    console.warn(error);
  }
  return undefined;
};

const uploadFileToBlob = ({ blobName, file }) => {
  if (!file) return null;
  const blobService = new BlobServiceClient(appConfig.storageSasUrl);
  const containerClient = blobService.getContainerClient(appConfig.storageContaineName);

  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(blobName);

  // upload file
  return createBlobInContainer(blobClient, file);
};

export default uploadFileToBlob;

export const uploadFile = async (file) => {
  const payload = new FormData()
  payload.append("file", file)
  return await baseAxios.post('api/upload/', payload )
}