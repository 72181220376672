import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axios";
import {
  getSessionStorageValue,
  setLocalStorageValue,
  setSessionStorageValue,
} from "../utils/storage";
import { getUser } from "../utils/users/getUser";

export const fetchUser = createAsyncThunk("user/fetchUser", async (id) => {
  const response = await axios.get(`api/users/${id}`);
  return response.data;
});

const initialState = {
  id: null,
  profile_photo: null,
  role: null,
  first_name: null,
  surname: null,
  email: null,
  phone_number: null,
  employer: null,
  job_title: null,
  parent_group: null,
  site: null,
  head_office: null,
  is_active: null,
  is_staff: null,
  is_superuser: null,
  is_onboarded: false,
  is_mfa_enabled: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState: () => {
    const user = getUser();
    if (user) return user;
    return initialState;
  },

  reducers: {
    setUser: (state, action) => action.payload,
    updateUser: (state, action) => {
      if (getSessionStorageValue("user")) {
        setSessionStorageValue("user", JSON.stringify(action.payload));
      } else {
        setLocalStorageValue("user", JSON.stringify(action.payload));
      }
      return action.payload;
    },
    removeUser: () => initialState,
  },
  extraReducers: {
    [fetchUser.pending]: () => initialState,
    [fetchUser.fulfilled]: (state, action) => {
      state = action.payload;
    },
    [fetchUser.rejected]: () => initialState,
  },
});

export const { setUser, removeUser, updateUser } = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
