import React, {useEffect, useState} from 'react';
import {Typography} from "@mui/material";

const Timer = ({ time, onStop }) => {
  const [targetTime, setTargetTime] = useState(time)

  useEffect(() => {
    const timerId = setInterval(() => {
      setTargetTime(t => {
        if (t <= 0) {
          clearInterval(timerId)
        }
        return Math.max(t - 1, 0)
      })
    }, [1000])
    return () => {
      clearInterval(timerId)
    }
  }, []);

  useEffect(() => {
    if (!targetTime) {
      onStop && onStop()
    }
  }, [targetTime])

  return (
    <Typography>Resend code in 00:{targetTime.toString().padStart(2, '0')}</Typography>
  )
}

export default Timer;