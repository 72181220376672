import React, {useEffect, useRef} from "react";
import {useFormik} from "formik";
import {Stack} from "@mui/material";
import styled from "@emotion/styled";
import {COLORS} from "../../constants/base";

const Input = styled.input`
    width: 50px;
    height: 50px;
    border: 1px solid ${COLORS.gray_light};
    border-radius: 8px;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
`

const isNumber = value => /^\d$/.test(value)
const isValidCode = value => /^\d{4}$/.test(value)

const CodeInput = ({ onChange, value, length }) => {
  const formRef = useRef(null);
  const initValue = Array(length).fill(0)
    .reduce((acc, item, index) => ({...acc, [index]: value?.[index] || ''}), {})

  const { values, setFieldValue } = useFormik({
    initialValues: initValue,
  })
  const changeValue = key => event => {
    const value = event.target.value;
    if (!value || isNumber(value)) {
      setFieldValue(key, event.target.value)
      if (formRef.current) {
        const nextKey = !value ? key - 1 : key + 1
        formRef.current[nextKey]?.focus()
      }
    }
  }

  const handlePaste = event => {
    event.preventDefault()
    const code = event.clipboardData.getData('text').trim()
    if (isValidCode(code)) {
      code.split('').forEach((num, i) => setFieldValue(i, num))
    }
  }

  useEffect(() => {
    const value = Object.values(values).join('')
    if (onChange) {
      onChange(value)
    }
  }, [values])

  return (
    <form ref={formRef}>
      <Stack direction="row" spacing={2} justifyContent="center">
        {Array(length).fill(0).map((_, i) => (
          <Input onPaste={handlePaste} onChange={changeValue(i)} value={values[i]} name={i} key={i} />
        ))}
      </Stack>
    </form>
  )
}

export default CodeInput