import * as Yup from "yup";
import {isPhoneValid} from "../../utils/validation/isPhoneValid";

export const AnswerType = {
  Text: 'text',
  Number: 'number',
  Radio: 'radio',
  Dropdown: 'dropdown'
}
export const CUSTOM_ANSWER = 'customAnswer'
export const OTHER_ANSWER = "Others (please specify)"
const LENGTH_ERROR_MESSAGE = 'Answer must be at most 155 characters'
const requiredText = Yup.string().max(155, LENGTH_ERROR_MESSAGE).required('Reqired field')


export const ONBOARDING_QUESTIONS = [
  {
    field: 'first_name',
    question: 'First Name:',
    type: AnswerType.Text,
    validation: requiredText,
  },
  {
    field: 'surname',
    question: 'Last Name:',
    type: AnswerType.Text,
    validation: requiredText,
  },
  {
    field: 'employer',
    question: 'Who is your employer?',
    type: AnswerType.Text,
    validation: requiredText,
  },
  {
    field: 'job_title',
    question: 'Role in the Organisation:',
    type: AnswerType.Dropdown,
    answers: [
      {
        label: 'Head Chef',
        value: 'Head Chef',
      },
      {
        label: 'Sous or Junior Chef',
        value: 'Sous or Junior Chef',
      },
      {
        label: 'Manager',
        value: 'Manager',
      },
      {
        label: 'Sustainability Manager',
        value: 'Sustainability Manager',
      },
      {
        label: 'Owner/Corporate Manager',
        value: 'Owner/Corporate Manager',
      },
    ],
    canSkip: true,
  },
  {
    field: 'phone_number',
    question: 'Contact Number:',
    type: AnswerType.Number,
    validation: Yup.string().test('is-phone-valid', 'Phone number is invalid', isPhoneValid),
    canSkip: true,
  },
  {
    field: 'reduce_waste_reason',
    question: 'What is the main reason you want to reduce your food waste?',
    type: AnswerType.Dropdown,
    answers: [
      {
        label: 'Environmental',
        value: 'Environmental',
      },
      {
        label: 'Financial Savings',
        value: 'Financial Savings'
      },
      {
        label: 'Operational Efficiency',
        value: 'Operational Efficiency',
      },
    ],
    canSkip: true,
  },
  {
    field: 'waste_management_level',
    question: 'How well do you think food waste is managed in your organisation currently?',
    type: AnswerType.Radio,
    answers: [
      {
        value: "Outstanding",
        label: '🌟 Outstanding - We excel in reducing waste have implemented sustainability practices.',
      },
      {
        value: "Good",
        label: '👍 Good - We actively monitor and manage our food waste.',
      },
      {
        value: 'Fair',
        label: '➖ Fair - Our efforts are inconsistent and often depend on operational capacity.',
      },
      {
        value: 'Needs Improvement',
        label: '👎 Needs Improvement - Limited actions are taken towards waste management due to constraints.',
      },
      {
        value: 'Critical',
        label: '⚠ Critical - Struggling significantly with waste control and overproduction.',
      },
    ],
    canSkip: true,
  },
  {
    field: 'most_common_waste',
    question: 'What type of food waste do you think is most commonly occurring in your organisation?',
    type: AnswerType.Dropdown,
    answers: [
      {
        label: 'Preparation Waste',
        value: 'Preparation Waste',
      },
      {
        label: 'Production Waste',
        value: 'Production Waste',
      },
      {
        label: 'Inventory/Storage Spoilage',
        value: 'Inventory/Storage Spoilage',
      },
      {
        label: 'Others (please specify)',
        value: 'Others (please specify)',
      },
    ],
    customAnswerOn: 'Others (please specify)',
    validation: requiredText,
    canSkip: true,
  },
  {
    field: 'sustainable_waste_management_familiarity',
    question: 'How familiar are you with sustainable waste management practices?',
    type: AnswerType.Dropdown,
    answers: [
      {
        label: 'Very familiar',
        value: 'Very familiar',
      },
      {
        label: 'Somewhat familiar',
        value: 'Somewhat familiar',
      },
      {
        label: 'Not very familiar',
        value: 'Not very familiar',
      },
      {
        label: 'Not familiar at all',
        value: 'Not familiar at all',
      },
    ],
    canSkip: true,
  },
  {
    field: 'frequency',
    question: 'How often would you like to receive your reports?',
    type: AnswerType.Dropdown,
    answers: [
      {
        label: 'Daily',
        value: 'DAILY',
      },
      {
        label: 'Weekly',
        value: 'WEEKLY',
      },
      {
        label: 'Monthly',
        value: 'MONTHLY',
      }
    ]
  }
]
