import React, { useMemo } from 'react';
import Base from "../Base";
import {useSelector} from "react-redux";
import {selectExplore} from "../../../redux/explore";
import {WIDGET_IDENTITY} from "../config";
import Chart from "./components/Chart";
import dayjs from "dayjs";
import Loader from "../Loader";
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import {DATE_GRAN, LABELS, METRICS} from "../../../constants/explore";
import SimpleDialog from "../../dialogs/SimpleDialog";
import {useModal} from "mui-modal-provider";
import UpdateOccupancy from "./components/UpdateOccupancy";
import useIsMobile from "../../../hooks/useIsMobile";
import ConfirmModal from "../../../layouts/ConfirmModal";
import SetDefaultOccupancy from "./components/SetDefaultOccupancy";
import {mainRoutes} from "../../../constants/urls";
import {formatDataQuery} from "../../../utils/date/formatDataQuery";
import {useWidget} from "../useWidget";
import numberFormater from "../../../utils/formaters/numberFormater";

const startOfCurrentMonth = dayjs().startOf('month').format('YYYY-MM-DD')
const endDate = dayjs().endOf('month').format('YYYY-MM-DD')
const DEFAULT_TITLE = 'Choose how you\'d like to set the default occupancy:'

const Occupancy = () => {
  const { filters } = useSelector(selectExplore)
  const selectedSite = filters.sites[0]
  const chartTitle = `# People ${selectedSite?.name ? '' : ' - Average per All Sites'}`
  const { showModal } = useModal()
  const isMobile = useIsMobile()
  const params = useMemo(() => (
    {
      startDate: startOfCurrentMonth,
      endDate,
      ...(selectedSite?.id ? { site: selectedSite.id} : {})
    }
  ), [selectedSite?.id])

  const { data, isLoading, title, tip, goToExplore, updateCache } = useWidget(WIDGET_IDENTITY.OCCUPANCY, {
    fetchKey: ['api/daily-covers/statistics', params],
    exploreParams: {
      path: `/${mainRoutes.explore}`,
      params: {
        dateRange: [
          startOfCurrentMonth,
          formatDataQuery(new Date()),
        ],
        metric: METRICS.occupancy,
        siteId: selectedSite?.id,
        dateGranularity: DATE_GRAN.daily,
        labels: LABELS.occupancyType,
      }
    }
  })

  const isSiteSelected = !!selectedSite?.id
  const total = numberFormater({ value: data?.total_covers })

  const showUpdateModal = (date, value) => {
    const targetModal =  showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: <UpdateOccupancy closeModal={() => {
        targetModal.hide()
        updateCache()
      }} selectedSite={selectedSite?.id} dateToSet={date} refetchData={updateCache} value={value} />,
    })
  }

  const showDefaultModal = withDays => {
    const targetModal =  showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: <SetDefaultOccupancy closeModal={() => {
        targetModal.hide()
        updateCache()
      }} selectedSite={selectedSite?.id} withDays={withDays} />,
    })
  }

  const showChangeDefaultOptions = () => {
    const targetModal =  showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: <ConfirmModal
        onClose={() => showDefaultModal()}
        onConfirm={() => showDefaultModal(true)}
        canselText="Single daily Default"
        confirmText="Mon-Sun defaults"
        title={DEFAULT_TITLE}
        bothActive
        closeModal={() => targetModal.hide()}
      />,
    })
  }

  return (
    <Base title={title} tooltip={tip} id={WIDGET_IDENTITY.OCCUPANCY} value={ data && total} canUnpin>
      <Base.Content style={{ minHeight: '280px' }}>
        {isLoading && <Loader />}
        {!!data?.chart?.length &&  <Chart title={chartTitle} data={data} onBarClick={showUpdateModal} />}
      </Base.Content>
      {!!data?.chart && (
        <Base.Footer>
          <Stack direction="row" justifyContent={isSiteSelected ? "space-between" : "flex-end"}>
            {isSiteSelected && (
              <Button disabled={isLoading} onClick={showChangeDefaultOptions} variant="link">Set Default Occupancy</Button>
            )}
            {isSiteSelected && (
              <Button disabled={isLoading} onClick={() => showUpdateModal()} variant="link">Update Occupancy</Button>
            )}
            <Button disabled={isLoading} variant="link" onClick={goToExplore}>Explore Occupancy</Button>
          </Stack>
        </Base.Footer>
      )}
    </Base>
  );
};

export default Occupancy;
