import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Button as MuiButton } from "@mui/material";
import { spacing } from "@mui/system";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

import CropperImage from "./cropperImage";
import ZoomImage from "./ZoomImage";
import useWindowSize from "../../../hooks/useWindowSize";

const Button = styled(MuiButton)(spacing);

function EventImage({
  isFirst,
  event,
  boundingArea,
  setLoading,
  loading,
  disableMargin,
  disableZoom,
  setSelectedImage,
  enableSwitch = true,
  isFeedPage,
  expand,
  setImageReady = () => {},
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [postion, setPostion] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [isPrevImage, setIsPrevImage] = useState(false);
  const [isNextImage, setIsNextImage] = useState(false);
  const [isError, setIsError] = useState(false);

  const divId = useMemo(() => `event_${event?.id}${expand ? '-expand' : ''}`, [event?.id, expand]);
  const selector = useMemo(() => `#${divId} img`, [divId]);

  const ref = useRef(null)
  const { width: windowWidth} = useWindowSize()

  const updateSize = () => {
    if(ref.current) {
      const { width: containerWidth } = ref.current.getBoundingClientRect()
      setWidth(containerWidth)
      setHeight(containerWidth * 0.56)
    }
  }

  useLayoutEffect(() => {
    setTimeout(() => {
      updateSize()
    }, 100)
  }, []);

  useEffect(() => {
    updateSize()
  }, [windowWidth]);

  useEffect(() => {
    setLoading(true);
    setImageReady(false)
    if (event) {
      const backupImageUrl = event.backup_image_url || [];
      const copyBackup = Array.from(new Set(backupImageUrl));
      const originalImage = event.original_image
      const image = enableSwitch && originalImage
      if (isFeedPage && copyBackup.length) {
        setImageList([event.image_url, ...copyBackup])
        return
      }
      setImageList([image || event.image_url, ...copyBackup]);
    } else {
      setImageList([]);
    }
    setPostion(0);
  }, [event]);

  useEffect(async () => {
    setLoading(true);
    const src = imageList[postion];
    const imageSelector = `#${divId} img`;
    const image = document.querySelector(selector);

    if (image) {
      delete image.cropper;
    }

    try {
      CropperImage({
        src,
        selector: imageSelector,
        boundingArea,
        setImageUrl,
      });
    } catch (error) {
      setImageUrl(null);
      const message = src ? "Bad image" : "No image URL";
      enqueueSnackbar(message, { variant: "error" });
    } finally {
      setIsNextImage(postion + 1 < imageList.length);
      setIsPrevImage(postion - 1 >= 0);
      setLoading(false);
    }
  }, [postion, imageList]);

  useEffect(() => {
    if (!loading) {
      const image = document.querySelector(selector);
      if (imageList.length && image && !image.cropper && !image.src) {
        setIsError(true);
      }
    }
  }, [loading, imageList]);

  useEffect(() => {
    if (isError) {
      const newImageList = [...imageList];
      newImageList.splice(postion, 1);

      if (newImageList.length === 0) {
        enqueueSnackbar("No image", { variant: "error" });
        setImageReady(true)
      }

      setIsError(false);
      setImageList(newImageList);

      if (newImageList.length - 1 < postion) {
        setPostion((state) => state - 1);
      }
    }
  }, [isError]);

  const handlePrevPosition = () => {
    setLoading(true);
    const newPosition = postion - 1;

    if (newPosition < 0) {
      setPostion(null);
    } else {
      setPostion(newPosition);
    }
  };

  const handlenextPosition = () => {
    setLoading(true);
    const newPosition = postion + 1;

    if (newPosition >= imageList.length) {
      setPostion(null);
    }
    setPostion(newPosition);
  };

  const onErrorHandle = ({ currentTarget }) => {
    currentTarget.onerror = null;
    delete currentTarget.src;
    setImageUrl();
    setLoading(false);
    setIsError(true);
  };

  const onLoadHandle = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (imageUrl) {
      setSelectedImage(imageList[postion]);
    }
  }, [imageUrl, width, height]);

  return (
    <div ref={ref}>
      <div
        data-testid="event-image-div"
        style={{
          position: "relative",
          width: "100%",
          height: `${width * 0.56}px`,
          overflow: "hidden",
          margin: disableMargin ? 0 : "1rem auto",
        }}
      >
        <div id={divId} hidden>
          <img
            src={imageUrl}
            crossOrigin="anonymous"
            onError={onErrorHandle}
            onLoad={onLoadHandle}
            alt=""
          />
        </div>
        <ZoomImage
          imageUrl={imageUrl}
          disableZoom={disableZoom}
          width={width}
          height={height}
          setImageReady={setImageReady}
        />
        {event && event.id && enableSwitch && (
          <>
            <Button
              variant="text"
              disabled={!isPrevImage}
              onClick={handlePrevPosition}
              style={{ position: "absolute", bottom: 0, left: 0 }}
            >
              <ArrowBack />
            </Button>
            <Button
              variant="text"
              style={{ position: "absolute", bottom: 0, right: 0 }}
              disabled={!isNextImage}
              onClick={handlenextPosition}
            >
              <ArrowForward />
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

EventImage.propTypes = {
  isFirst: PropTypes.bool,
  event: PropTypes.shape({
    id: PropTypes.number,
    backup_image_url: PropTypes.arrayOf(PropTypes.string),
    food: PropTypes.number,
    image_url: PropTypes.string,
    logged_at: PropTypes.string,
    item_weight: PropTypes.string,
    site: PropTypes.string,
  }),
  boundingArea: PropTypes.arrayOf(PropTypes.number),
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  disableMargin: PropTypes.bool,
  disableZoom: PropTypes.bool,
  setSelectedImage: PropTypes.func,
};

EventImage.defaultProps = {
  isFirst: false,
  event: null,
  boundingArea: [0, 0, 1920, 1080],
  setLoading: () => {},
  loading: undefined,
  disableMargin: false,
  disableZoom: false,
  setSelectedImage: () => {},
};

export default EventImage;
