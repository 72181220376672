import React, {useState, useEffect, useCallback, useMemo, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useSnackbar } from "notistack";
import getEvents from "./axios/getEvents";
import getLeftTime from "./axios/getLeftTime";
import { fetchFoods } from "../../redux/foods";
import secondsToTime from "../labelling/utils/secondsToTime";
import {selectFilters} from "../../redux/filters";
import arraysEqual from "../../utils/data/arraysEqual";

const getKeys = (list, key) => list.map((item) => item[key])
const getEventFilters = ({ site, zone }) => ({
  site: getKeys(site, 'id'),
  serial: getKeys(zone, 'serial'),
})

const isFiltersMatch = (prevFilters, filters) => arraysEqual(prevFilters.site, filters.site)
  && arraysEqual(prevFilters.serial, filters.serial)

function WithVerification(Component) {
  // eslint-disable-next-line react/display-name
  return function () {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { filters } = useSelector(selectFilters)

    const [event, setEvent] = useState(null);
    const [previousEvent, setPreviousEvent] = useState(null);
    const [loadingEvent, setLoadingEvent] = useState(true);
    const [loadingPreviousEvent, setLoadingPreviousEvent] = useState(true);
    const [leftTime, setLeftTime] = useState(null);
    const [progress, setProgress] = useState(0);
    const [alert, setAlert] = useState(false);
    const completedTasks = leftTime && leftTime.total_events - leftTime.irl;
    const titleToEnd = leftTime && secondsToTime(leftTime.alt * leftTime.irl);
    const [trimmings, setTrimmings] = useState(false);
    const [filtersChanged, setFiltersChanged] = useState(false)
    const prevFilters = useRef(getEventFilters(filters))
    const [selectedImage, setSelectedImage] = useState('');

    const reset = useCallback(() => {
      setLeftTime(null)
      setEvent(null);
      setPreviousEvent(null);
    }, []);

    useEffect(() => {
      dispatch(fetchFoods({ labelling: true }));
    }, [dispatch]);

    useEffect(() => {
      const eventFilters = getEventFilters(filters)

      if (!isFiltersMatch(prevFilters.current, eventFilters)) {
        setLeftTime(null)
        reset()
        setFiltersChanged(_ => !_)
        prevFilters.current = eventFilters
      }
    },[filters] )


    useEffect(async () => {
      if (!event) {
        const eventFilters = getEventFilters(filters)
        const events = await getEvents(
          setEvent,
          setPreviousEvent,
          setLoadingEvent,
          setLoadingPreviousEvent,
          setAlert,
          eventFilters,
        );
        if (!leftTime) {
          const res = await getLeftTime(eventFilters);
          if (res.status === 200) {
            setLeftTime({
              ...res.data,
              serial: events?.event?.device_serial,
              site_name: events?.event?.site_name,
            });
          } else {
            setLeftTime(null);
            enqueueSnackbar("Oops, something went wrong.", {
              variant: "error",
            });
          }
        }
      } else {
        setTrimmings(event.trimmings)
      }
    }, [event, reset, filtersChanged]);

    useEffect(() => {
      if (leftTime) {
        const taskProgress =
          ((leftTime.total_events - leftTime.irl) * 100) /
          leftTime.total_events;

        if (Number.isNaN(taskProgress)) {
          setProgress(100);
        } else {
          setProgress(taskProgress);
        }
      }
    }, [leftTime]);

    return (
      <Component
        event={event}
        previousEvent={previousEvent}
        loadingEvent={loadingEvent}
        setLoadingEvent={setLoadingEvent}
        loadingPreviousEvent={loadingPreviousEvent}
        setLoadingPreviousEvent={setLoadingPreviousEvent}
        setLeftTime={setLeftTime}
        leftTime={leftTime}
        completedTasks={completedTasks}
        titleToEnd={titleToEnd}
        progress={progress}
        reset={reset}
        alert={alert}
        trimmings={trimmings}
        setTrimmings={setTrimmings}
        setSelectedImage={setSelectedImage}
        selectedImage={selectedImage}
      />
    );
  };
}

export default WithVerification;
