import React from 'react';
import WhiteBlock from '../blocks/WhiteBlock'
import {useDispatch, useSelector} from "react-redux";
import {selectUser, updateUser} from "../../redux/user";
import {selectProfile} from "../../redux/profile";
import SwitchFilter from "../filter/components/SwitchFilter";
import userService from "../../services/UserService";
import SimpleDialog from "../dialogs/SimpleDialog";
import EnableMFAModal from "./EnableMFAModal";
import useIsMobile from "../../hooks/useIsMobile";
import {useModal} from "mui-modal-provider";

const Mfa = () => {
  const { id, is_mfa_enabled } = useSelector(selectUser)
  const { data } = useSelector(selectProfile)
  const dispatch = useDispatch();
  const isMobile = useIsMobile()
  const { showModal } = useModal()

  const showFoodModal = () => {
    const targetModal =  showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: <EnableMFAModal close={() => targetModal.hide()} />,
    })
  }

  const toggle2fa = (value) => {
    if (value) {
      showFoodModal()
      return;
    }
    dispatch(userService.updateUser(id, { is_mfa_enabled: value }))
  }

  return (
    <WhiteBlock title="Security">
      <SwitchFilter
        label="Enable 2FA"
        value={is_mfa_enabled}
        setValue={toggle2fa}
        clickable={false}
      />
    </WhiteBlock>
  );
};

export default Mfa;