import axiosInstance from "../axios";

export const axiosUpdateUser = async ({
  id,
  profilePhoto,
  role,
  firstName,
  surname,
  email,
  phoneNumber,
  employer,
  jobTitle,
  parentGroup,
  site,
  reduceWasteReason,
  wasteManagementLevel,
  mostCommonWaste,
  wasteManagementFamiliarity,
  isMfaEnabled,
}) => {
  const url = `api/users/${id}`;
  const data = {
    profile_photo: profilePhoto,
    role,
    first_name: firstName,
    surname,
    email,
    employer,
    job_title: jobTitle,
    parent_group: parentGroup,
    site,
    reduce_waste_reason: reduceWasteReason,
    waste_management_level: wasteManagementLevel,
    most_common_waste: mostCommonWaste,
    sustainable_waste_management_familiarity: wasteManagementFamiliarity,
    phone_number: phoneNumber || null,
    is_mfa_enabled: isMfaEnabled,
  };

  const res = await axiosInstance.put(url, data);
  return res;
};

export const resetMfa = (currentPhone, newPhone) => currentPhone !== newPhone
  ? { isMfaEnabled: false }
  : {}
