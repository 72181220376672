import React from 'react';
import {Box, Divider, Stack, Typography} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {Alert, Wrapper} from "../styled/GlobalStyled";
import {COLORS} from "../../constants/base";
import useAuth from "../../hooks/useAuth";
import BackButton from "./BackButton";

const FormLayout = ({ children, title, headTitle, showBackButton, backPath, onBackButtonClick, subtitle }) => {
  const { error } = useAuth()
  return (
    <Wrapper elevation={0}>
      <Stack spacing={5}>
        {showBackButton && (
          <div style={{ alignSelf: 'flex-start' }}>
            <BackButton path={backPath} onClick={onBackButtonClick} />
          </div>
        )}
        <Stack>
          <Typography variant="medium" sx={{ color: COLORS.darkBlue2 }} component="h1" align="left">
            {title}
          </Typography>
          {subtitle && (
            <Typography variant='subtitle1' sx={{ color: COLORS.gray_light }}>{subtitle}</Typography>
          )}
        </Stack>
        <Divider />
        <Helmet title={headTitle} />
        {error && (
          <Alert mt={2} mb={3} severity="warning">
            {error}
          </Alert>
        )}
        {children}
      </Stack>
    </Wrapper>
  );
};

export default FormLayout;
