import React, {useState} from "react";
import {Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {COLORS} from "../../constants/base";
import Timer from "./Timer";

const ActionWithTimer = ({ action, wait = 60 }) => {
  const [waitTime, setWaitTime] = useState(0)

  const resendCode = () => {
    action()
      .then(() => setWaitTime(wait))
  }

  const clearWaitTime = () => setWaitTime(0)

  return (
    <Stack direction="row" justifyContent="center" spacing={2}>
      {
        !!waitTime
          ? <Timer time={waitTime} onStop={clearWaitTime} />
          : (
            <>
              <Typography>Didn't receive the code? </Typography>
              <Button
                sx={{
                  color: COLORS.darkBlue2,
                  fontSize: "13px",
                  fontWeight: 400,
                  padding: 0,
                  marginBottom: "2px",
                  textDecoration: 'underline',
                  display: 'inline'
                }}
                onClick={resendCode}
              >
                Click here to try again
              </Button>
            </>
          )
      }
    </Stack>
  )
}
export default ActionWithTimer;
