import { useMediaQuery } from "@mui/material";

const useIsMobile = () => {
  return useMediaQuery((theme) => theme.breakpoints.down("md"));
};

export const useIsMiniMobile = () => {
  return useMediaQuery((theme) => theme.breakpoints.down("sm"));
}

export default useIsMobile;
