import {
  Stack,
  TextField,
  DialogContent,
  FormControl,
  useMediaQuery,
  DialogActions, InputLabel, Select, MenuItem, Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import {uploadFile} from "../../utils/uploadImage";
import {axiosUpdateUser, resetMfa} from "../../utils/users/axiosUpdateUser";
import { selectUser, updateUser } from "../../redux/user";
import CustomButton from "../components/Buttons/CustomButton";
import UserAvatar from "../usersManagement/userAvatar";
import useAppSelector from "../../hooks/useAppSelector";
import React from "react";
import { OTHER_ANSWER } from "../onboarding/questions";
import MuiPhone from "../inputs/MuiPhone";
import {isPhoneValid} from "../../utils/validation/isPhoneValid";
import {localDateFormat} from "../../utils/date/localDate";

const COMMON_WASTE = [
  "Preparation Waste",
  "Production Waste",
  "Plate Waste",
  "Inventory/Storage Spoilage",
  OTHER_ANSWER,
]

function UserEditFrom({ onCancel }) {
  const {
    id,
    email,
    first_name: firstName,
    surname,
    profile_photo: profilePhoto,
    role,
    phone_number: phoneNumber,
    job_title: jobTitle,
    employer,
    reduce_waste_reason: reduceWasteReason,
    waste_management_level: wasteManagementLevel,
    most_common_waste: mostCommonWaste,
    sustainable_waste_management_familiarity: wasteManagementFamiliarity,
    onboarding_date: onboardingDate,
  } = useAppSelector(selectUser);

  const dispatch = useDispatch();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { enqueueSnackbar } = useSnackbar();
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    values,
  } = useFormik({
    initialValues: {
      file: "",
      expansion: "",
      id: id,
      profilePhoto: profilePhoto,
      role: role,
      firstName: firstName,
      surname: surname,
      email: email,
      phoneNumber: phoneNumber || "",
      employer: employer,
      jobTitle: jobTitle,
      submit: false,
      reduceWasteReason,
      wasteManagementLevel,
      mostCommonWaste: (COMMON_WASTE.includes(mostCommonWaste) || !mostCommonWaste) ? mostCommonWaste : OTHER_ANSWER,
      wasteManagementFamiliarity,
      customAnswer: COMMON_WASTE.includes(mostCommonWaste) ? '' : mostCommonWaste,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().max(255).required("First name is required"),
      surname: Yup.string().max(255).required("Last name is required"),
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      phoneNumber: Yup.string().test('is-phone-valid', 'Phone number is invalid', isPhoneValid),
      employer: Yup.string().max(255).required("Employer is required"),
      jobTitle: Yup.string().max(255).required("Job title is required"),
      customAnswer: Yup.string().nullable().when('mostCommonWaste', {
        is: OTHER_ANSWER,
        then: Yup.string().max(100, 'Answer can not be more than 100 characters').nullable().required("Answer is required"),
      })
    }),
    onSubmit: submitHandler,
  });

  async function submitHandler(
    values,
    { setErrors, setStatus, setSubmitting }
  ) {
    try {
      let avatar;
      if (values.file) {
        const { file } = values;
        const { data: { url } } = await uploadFile(file)
        if (url) {
          avatar = url;
        }
      }
      const res = await axiosUpdateUser({
        id: values.id,
        role: values.role,
        firstName: values.firstName,
        surname: values.surname,
        email: values.email,
        phoneNumber: values.phoneNumber,
        employer: values.employer,
        jobTitle: values.jobTitle,
        reduceWasteReason: values.reduceWasteReason,
        wasteManagementLevel: values.wasteManagementLevel,
        mostCommonWaste: values.mostCommonWaste !== OTHER_ANSWER ? values.mostCommonWaste : values.customAnswer,
        wasteManagementFamiliarity: values.wasteManagementFamiliarity,
        ...(avatar ? { profilePhoto: avatar } : {}),
        ...resetMfa(phoneNumber, values.phoneNumber),
      });
      if (res.status === 200) {
        dispatch(updateUser(res.data));
      } else {
        enqueueSnackbar("Oops, something went wrong.", {
          variant: "error",
        });
      }
      onCancel();
    } catch (error) {
      const message = error.message || error.detail || "Something went wrong";
      setStatus({ success: false });
      setErrors({ submit: message });
      setSubmitting(false);
    }
  }

  return (
    <>
      <DialogContent>
        <form noValidate onSubmit={handleSubmit}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={4}
          >
            <FormControl fullWidth>
              <UserAvatar values={values} setFieldValue={setFieldValue} />
            </FormControl>
            <Stack
              direction={isMobile ? "column" : "row"}
              justifyContent="space-between"
              alignItems="baseline"
              spacing={4}
            >
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  type="text"
                  name="firstName"
                  label="First Name"
                  value={values.firstName}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  type="text"
                  name="surname"
                  label="Last Name"
                  value={values.surname}
                  error={Boolean(touched.surname && errors.surname)}
                  helperText={touched.surname && errors.surname}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </FormControl>
            </Stack>
            <FormControl fullWidth>
              <TextField
                variant="standard"
                type="email"
                name="email"
                label="Email"
                fullWidth
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl fullWidth>
              <MuiPhone
                variant="standard"
                type="tel"
                name="phoneNumber"
                label="Phone Number"
                fullWidth
                value={values.phoneNumber}
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber}
                onBlur={handleBlur}
                onChange={data => setFieldValue('phoneNumber', data)}
              />
            </FormControl>
            <Stack
              direction={isMobile ? "column" : "row"}
              justifyContent="space-between"
              alignItems="baseline"
              spacing={4}
            >
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  type="text"
                  name="employer"
                  label="Employer"
                  value={values.employer}
                  error={Boolean(touched.employer && errors.employer)}
                  helperText={touched.employer && errors.employer}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="jobTitle">Job Title</InputLabel>
                <Select
                  id="jobTitle"
                  name="jobTitle"
                  value={values.jobTitle}
                  error={Boolean(touched.jobTitle && errors.jobTitle)}
                  helperText={touched.jobTitle && errors.jobTitle}
                  onChange={handleChange}
                >
                  <MenuItem value="Head Chef">Head Chef</MenuItem>
                  <MenuItem value="Sous or Junior Chef">Sous or Junior Chef</MenuItem>
                  <MenuItem value="Manager">Manager</MenuItem>
                  <MenuItem value="Sustainability Manager">Sustainability Manager</MenuItem>
                  <MenuItem value="Owner/Corporate Manager">Owner/Corporate Manager</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="reduceWasteReason">What is the main reason you want to reduce your food waste?</InputLabel>
              <Select
                id="reduceWasteReason"
                name="reduceWasteReason"
                value={values.reduceWasteReason}
                onChange={handleChange}
              >
                <MenuItem value="Environmental">Environmental</MenuItem>
                <MenuItem value="Financial Savings">Financial Savings</MenuItem>
                <MenuItem value="Operational Efficiency">Operational Efficiency</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="wasteManagementLevel">How well do you think food waste is managed in your organisation currently?</InputLabel>
              <Select
                id="wasteManagementLevel"
                name="wasteManagementLevel"
                value={values.wasteManagementLevel}
                onChange={handleChange}
              >
                <MenuItem value="Outstanding">Outstanding</MenuItem>
                <MenuItem value="Good">Good</MenuItem>
                <MenuItem value="Fair">Fair</MenuItem>
                <MenuItem value="Needs Improvement">Needs Improvement</MenuItem>
                <MenuItem value="Critical">Critical</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="mostCommonWaste">What type of food waste do you think is most commonly occurring in your organisation?</InputLabel>
              <Select
                id="mostCommonWaste"
                name="mostCommonWaste"
                value={values.mostCommonWaste}
                onChange={handleChange}
              >
                <MenuItem value="Preparation Waste">Preparation Waste</MenuItem>
                <MenuItem value="Production Waste">Production Waste</MenuItem>
                <MenuItem value="Plate Waste">Plate Waste</MenuItem>
                <MenuItem value="Inventory/Storage Spoilage">Inventory/Storage Spoilage</MenuItem>
                <MenuItem value="Others (please specify)">Others (please specify)</MenuItem>
              </Select>
              {values.mostCommonWaste === 'Others (please specify)' && (
                <TextField
                  variant='standard'
                  name='customAnswer'
                  value={values.customAnswer}
                  onChange={handleChange}
                  error={!!errors.customAnswer}
                  helperText={errors.customAnswer}
                  placeholder="Type answer..."
                />
                )}
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="wasteManagementFamiliarity">How familiar are you with sustainable waste management practices?</InputLabel>
              <Select
                id="wasteManagementFamiliarity"
                name="wasteManagementFamiliarity"
                value={values.wasteManagementFamiliarity}
                onChange={handleChange}
              >
                <MenuItem value="Very familiar">Very familiar</MenuItem>
                <MenuItem value="Somewhat familiar">Somewhat familiar</MenuItem>
                <MenuItem value="Not very familiar">Not very familiar</MenuItem>
                <MenuItem value="Not familiar at all">Not familiar at all</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ margin: "0 1rem 1rem", justifyContent: 'space-between', flexWrap: 'wrap', gap: '10px' }}>
        <Typography>Login date: {localDateFormat(onboardingDate, { weekDay: true })}</Typography>
        <CustomButton type="submit" fullWidth={isMobile} onClick={handleSubmit}>
          Update User
        </CustomButton>
      </DialogActions>
    </>
  );
}

export default UserEditFrom;
