import React, {useEffect, useMemo, useState} from 'react';
import {
  Divider,
  FormControl, FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import BaseModal from "../../../../layouts/Modal";
import Button from "@mui/material/Button";
import {useFormik} from "formik";
import {selectExplore} from "../../../../redux/explore";
import {ALL_SITES} from "../../../../constants/widgets";
import {baseAxios, fetcher} from "../../../../utils/axios";
import {useSelector} from "react-redux";
import {selectSites} from "../../../../redux/sites";
import styled from "@emotion/styled";
import numberFormater from "../../../../utils/formaters/numberFormater";
import * as Yup from 'yup'
import useIsMobile from "../../../../hooks/useIsMobile";
import {getTargetByMetric} from "../../MonthlySnapshot/helpers";
import {METRICS} from "../../../../constants/explore";
import useSWR from "swr";
import Loader from "../../Loader";
import {useChangeTarget} from "../../useChangeTarget";
import site from "../../../../props/foodProp";

const TIP = 'Set your site’s focus foods for this month. For each chosen food, set a waste threshold in kg. The recommended waste threshold is based off previous month’s waste data multiplied by a target 50% reduction. Create reduction measures to stay under this threshold this month.'

const Padding = styled.div`
    padding-left: 10px;
    margin-top: 22px;
    flex: 3;
`
const FoodWrapper = styled.div`
  flex: 4;
  width: 220px;
  min-width: 0;
`

const MAX_THRESHOLD = 1000000000 // 1B

const thresholdValidation = Yup.object().shape({
  threshold: Yup.number()
    .when('id', {
      is: val => !!val,
      then: Yup.number()
        .max(MAX_THRESHOLD, `Threshold can not be more than ${MAX_THRESHOLD}`)
        .required(`Threshold should be a number from 0.001 to ${MAX_THRESHOLD}`)
    }),
  id: Yup.string().test('id-required', 'Required field', function(value) {
    const { threshold } = this.parent;
    if (threshold !== undefined && threshold !== null && threshold !== '') {
      return !!value;
    }
    return true;
  })
})

const allowedValueRegex = /^\d+(\.\d{0,3})?$/
const isSingleSite = props => props.site !== ALL_SITES

const getRecommendTarget = siteId => foodId => baseAxios.get(`api/overview/recommended_waste_target/?site=${siteId}&food=${foodId}&widget=focus_food`)

const FocusFoodForm = ({ closeModal, focusFood }) => {
  const { filters } = useSelector(selectExplore)
  const { sites } = useSelector(selectSites)
  const isMobile = useIsMobile()
  const isMultiSites = sites.length > 1
  const [target1, setTarget1] = useState()
  const [target2, setTarget2] = useState()
  const [target3, setTarget3] = useState()
  const [isReady, setIsReady] = useState(true)
  const selectedSite = filters.sites[0]
  const getWasteTarget = getTargetByMetric(METRICS.totalWaste)
  const { confirm } = useChangeTarget({ shouldShow: isSingleSite })
  const {values, handleChange, handleSubmit, errors, dirty, isValid, touched} = useFormik({
    initialValues: {
      site: selectedSite?.id || ALL_SITES,
      food1: {
        id: focusFood[0]?.id,
        threshold: getWasteTarget(focusFood[0]?.monthly_targets),
      },
      food2: {
        id: focusFood[1]?.id,
        threshold: getWasteTarget(focusFood[1]?.monthly_targets),
      },
      food3: {
        id: focusFood[2]?.id,
        threshold: getWasteTarget(focusFood[2]?.monthly_targets),
      },
    },
    validationSchema: Yup.object().shape({
      food1: thresholdValidation,
      food2: thresholdValidation,
      food3: thresholdValidation
    }),
    onSubmit: confirm(submit),
  })

  const params = useMemo(() => values.site !== ALL_SITES && { site: values.site }, [values.site])

  const { data: foods = [], isLoading } = useSWR(['api/foods/categorised', params], fetcher)

  const getTarget = useMemo(() => getRecommendTarget(values.site), [values.site])

  useEffect(() => {
    if(values.site && values.food1.id) {
      getTarget(values.food1.id)
        .then(res => setTarget1(res.data?.proposed_target || res.data?.message))
    }
  }, [values.food1.id, getTarget]);

  useEffect(() => {
    if(values.site && values.food2.id) {
      getTarget(values.food2.id)
        .then(res => setTarget2(res.data?.proposed_target || res.data?.message))
    }
  }, [values.food2.id, getTarget]);

  useEffect(() => {
    if(values.site && values.food3.id) {
      getTarget(values.food3.id)
        .then(res => setTarget3(res.data?.proposed_target || res.data?.message))
    }
  }, [values.food3.id, getTarget]);

  function submit({site, ...food}) {
    setIsReady(false)
    const payload = {
      site: site === ALL_SITES ? null : site,
      food: Object.values(food).filter(item => item.id),
    }
    baseAxios.post("api/foods/set_monthly_target/", payload)
      .then(closeModal)
      .finally(() => setIsReady(true))
  }

  const formattedTarget = (value) => numberFormater({ value, fixed: 2 })

  const renderTarget = target => (
    typeof target === 'string'
      ? (
        <Typography variant="small1">{target}</Typography>
      )
      : (
        <Typography variant="small1">Recommended Target: {formattedTarget(target)}kg</Typography>
      )
  )

  const handleThresholdChange = event => {
    const value = event.target.value
    if(!value || allowedValueRegex.test(value)) {
      handleChange(event)
    }
  }

  const reset = id => () => {
    const payload = {
      ...values,
      [id]: {
        ...values[id],
        threshold: null,
      }
    }
    submit(payload)
  }

  return (
    <BaseModal title="Update your Focus Foods" tooltip={!isMobile && TIP}>
      {isLoading && <Loader />}
      <form onSubmit={handleSubmit}>
        <Stack spacing={4}>
          {isMultiSites && (
            <>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="sites">Select Site</InputLabel>
                <Select
                  id="site"
                  placeholder="All Sites"
                  name="site"
                  value={values.site}
                  onChange={handleChange}
                >
                  <MenuItem value="all">All Sites</MenuItem>
                  {sites.map(site => (
                    <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Divider />
            </>
          )}
          <div>
            <Stack direction="row" alignItems="flex-start">
              <FoodWrapper>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="food1">Focus Food #1</InputLabel>
                  <Select
                    id="food1"
                    placeholder="Focus Food"
                    name="food1.id"
                    value={values.food1.id}
                    onChange={handleChange}
                    error={errors.food1?.id}
                  >
                    {foods.map(food => (
                      <MenuItem key={food.id} value={food.id}>{food.name}</MenuItem>
                    ))}
                  </Select>
                  {errors.food1?.id && (
                    <FormHelperText error>{errors.food1?.id}</FormHelperText>
                  )}
                </FormControl>
              </FoodWrapper>
              <Padding>
                <TextField
                  name="food1.threshold"
                  value={values.food1.threshold}
                  placeholder="Threshold (kg)"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  onChange={handleThresholdChange}
                  error={errors.food1?.threshold}
                  helperText={errors.food1?.threshold}
                />
              </Padding>
            </Stack>
            {!!getWasteTarget(focusFood[0]?.monthly_targets) && (
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  variant="text"
                  onClick={confirm(reset('food1'), { clear: true })}
                >
                  Clear
                </Button>
              </Stack>
            )}
            {target1 && values.site !== ALL_SITES && renderTarget(target1)}
          </div>
          <Divider />
          <div>
            <Stack direction="row" alignItems="flex-start">
              <FoodWrapper>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="food2">Focus Food #2</InputLabel>
                  <Select
                    id="food2"
                    placeholder="Focus Food"
                    name="food2.id"
                    value={values.food2.id}
                    onChange={handleChange}
                    error={errors.food2?.id}
                  >
                    {foods.map(food => (
                      <MenuItem key={food.id} value={food.id}>{food.name}</MenuItem>
                    ))}
                  </Select>
                  {errors.food2?.id && (
                    <FormHelperText error>{errors.food2?.id}</FormHelperText>
                  )}
                </FormControl>
              </FoodWrapper>
              <Padding>
                <TextField
                  name="food2.threshold"
                  value={values.food2.threshold}
                  placeholder="Threshold (kg)"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  onChange={handleThresholdChange}
                  error={errors.food2?.threshold}
                  helperText={errors.food2?.threshold}
                />
              </Padding>
            </Stack>
            {!!getWasteTarget(focusFood[1]?.monthly_targets) && (
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  variant="text"
                  onClick={confirm(reset('food2'), { clear: true })}
                >
                  Clear
                </Button>
              </Stack>
            )}
            {target2 && values.site !== ALL_SITES && renderTarget(target2)}
          </div>
          <Divider />
          <div>
            <Stack direction="row" alignItems="flex-start">
              <FoodWrapper>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="food3">Focus Food #3</InputLabel>
                  <Select
                    id="food3"
                    placeholder="Focus Food"
                    name="food3.id"
                    value={values.food3.id}
                    error={errors.food3?.id}
                    onChange={handleChange}
                  >
                    {foods.map(food => (
                      <MenuItem key={food.id} value={food.id}>{food.name}</MenuItem>
                    ))}
                  </Select>
                  {errors.food3?.id && (
                    <FormHelperText error>{errors.food3?.id}</FormHelperText>
                  )}
                </FormControl>
              </FoodWrapper>
              <Padding>
                <TextField
                  name="food3.threshold"
                  value={values.food3.threshold}
                  placeholder="Threshold (kg)"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  onChange={handleThresholdChange}
                  error={errors.food3?.threshold}
                  helperText={errors.food3?.threshold}
                />
              </Padding>
            </Stack>
            {!!getWasteTarget(focusFood[2]?.monthly_targets) && (
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  variant="text"
                  onClick={confirm(reset('food3'), { clear: true })}
                >
                  Clear
                </Button>
              </Stack>
            )}
            {target3 && values.site !== ALL_SITES && renderTarget(target3)}
          </div>
          <Divider />
          <Button disabled={!dirty || !isValid || !isReady} type="submit" variant="contained" color="secondary">Set Focus Food</Button>
        </Stack>
      </form>
    </BaseModal>
  );
};

export default FocusFoodForm;
