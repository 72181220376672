import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Paper, Stack, Typography} from "@mui/material";
import { Helmet } from "react-helmet-async";
import useMediaQuery from "@mui/material/useMediaQuery";
import EventStatistic from "../../components/labelling/EventStatistic";
import ImageSection from "../../components/labelling/ImageSection";
import withVerification from "../../components/eventsVerification/withVerification";
import ButtonSection from "../../components/eventsVerification/ButtonSection";
import EventAlert from "../../components/labelling/EventAlert";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import Page from "../../layouts/Page";
import SiteDeviceFilter from "../../components/filter/SiteDeviceFilter";
import useIsMobile from "../../hooks/useIsMobile";
import {withSites} from "../../hoc/withSites";

function Verification({
  event,
  previousEvent,
  loadingEvent,
  setLoadingEvent,
  loadingPreviousEvent,
  setLoadingPreviousEvent,
  progress,
  leftTime,
  setLeftTime,
  completedTasks,
  titleToEnd,
  reset,
  alert,
  trimmings,
  setTrimmings,
  setSelectedImage,
  selectedImage,
}) {
  const middle = useMediaQuery("(max-width: 800px)");
  const mixpanelTrack = useMixpanelTrack();
  const isMobile = useIsMobile()
  const [imageReady, setImageReady] = useState(false);

  useEffect(() => {
    mixpanelTrack("Verification - Page View");
  }, []);

  const setOpenHandle = () => {
    mixpanelTrack("Verification - Discard - Open Dialog");
  };

  return (
    <Page title="Verification">
      <Stack spacing={8}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="title" component="h1">Verification</Typography>
          {!isMobile && (
            <SiteDeviceFilter loading={!imageReady && !alert} aiLabel onFilterChange={() => setImageReady(false)} />
          )}
        </Stack>
        <Paper style={{ padding: "1rem" }}>
          {alert ? (
            <EventAlert />
          ) : (
            <EventStatistic
              timeData={leftTime}
              completedTasks={completedTasks}
              titleToEnd={titleToEnd}
              progress={progress}
            />
          )}
          <ImageSection
            md={middle}
            leftEvent={previousEvent}
            rightEvent={event}
            loadingLeftEvent={loadingPreviousEvent}
            setLoadingLeftEvent={setLoadingPreviousEvent}
            loadingRightEvent={loadingEvent}
            setLoadingRigthEvent={setLoadingEvent}
            isDelete={false}
            setOpenHandle={setOpenHandle}
            setImageReady={setImageReady}
            setSelectedImage={setSelectedImage}
          />
          <ButtonSection
            event={event}
            reset={reset}
            setLeftTime={setLeftTime}
            loadingEvent={loadingEvent}
            setLoadingEvent={setLoadingEvent}
            trimmings={trimmings}
            setTrimmings={setTrimmings}
            imageReady={imageReady}
            selectedImage={selectedImage}
          />
        </Paper>
      </Stack>
    </Page>
  );
}

Verification.propTypes = {
  event: PropTypes.shape({}),
  previousEvent: PropTypes.shape({}),
  loadingEvent: PropTypes.bool.isRequired,
  setLoadingEvent: PropTypes.func.isRequired,
  loadingPreviousEvent: PropTypes.bool.isRequired,
  setLoadingPreviousEvent: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  leftTime: PropTypes.shape({
    alt: PropTypes.number.isRequired,
    irl: PropTypes.number.isRequired,
    total_events: PropTypes.number.isRequired,
  }),
  setLeftTime: PropTypes.func.isRequired,
  completedTasks: PropTypes.number,
  titleToEnd: PropTypes.string,
  reset: PropTypes.func.isRequired,
  alert: PropTypes.bool.isRequired,
};

Verification.defaultProps = {
  event: null,
  previousEvent: null,
  leftTime: null,
  completedTasks: null,
  titleToEnd: null,
};

export default withSites(withVerification(Verification), { aiLabel: true });
