import dayjs from "dayjs";
import {COLORS} from "./base";

export const METRICS = {
  totalWaste: 'total_waste',
  coastWaste: 'waste_value',
  wasteEmission: 'waste_co2',
  AVGWaste: 'avg_waste',
  mealWaste: 'equivalent_meals',
  wasteLogs: 'count_events',
  waterFootprint: 'waste_footprint',
  occupancy: 'occupancy',
  wastePerCover: 'waste_per_cover',
}

export const METRICS_LABEL = {
  [METRICS.totalWaste]: 'Total Waste (KG)',
  [METRICS.coastWaste]: 'Cost of Waste (EUR)',
  [METRICS.wasteEmission]: 'Waste Emissions (CO2eq)',
  [METRICS.AVGWaste]: 'AVG Waste per site (KG)',
  [METRICS.mealWaste]: 'Waste in Meals',
  [METRICS.wasteLogs]: 'Waste Logs (old name: Interactions)',
  [METRICS.waterFootprint]: 'Water footprint',
  [METRICS.occupancy]: 'Occupancy',
  [METRICS.wastePerCover]: 'Waste per cover',
}

export const METRIC_LABEL_BADGE = {
  [METRICS.wastePerCover]: '1 Site Only',
}

export const LABELS = {
  none: 'none',
  category: 'category',
  subcategory: 'subcategory',
  focusFood: 'focus_food',
  trimmings: 'trimmings',
  nonFood: 'non-food',
  sites: 'sites',
  monitors: 'monitors',
  occupancyType: 'occupancy_type',
}
export const LABELS_LABEL = {
  [LABELS.category]: 'Category',
  [LABELS.subcategory]: 'Subcategory',
  [LABELS.focusFood]: 'Focus Foods',
  [LABELS.trimmings]: 'Trimmings',
  [LABELS.none]: "None",
  [LABELS.nonFood]: 'Non Food',
  [LABELS.sites]: 'Sites',
  [LABELS.monitors]: 'Monitors',
  [LABELS.occupancyType]: "Event Type"
}

export const DATE_GRAN = {
  hourly: 'hourly',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  quarterly: 'quarterly',
  annually: 'annually',
}

export const CHART_TYPE = {
  bar: 'bar',
  line: 'line',
  table: 'table',
}

export const MAX_AVAILABLE_DATE_BY_GRAN = {
  [DATE_GRAN.hourly]: 7, // week
  [DATE_GRAN.daily]: 180, // 6 month
  [DATE_GRAN.weekly]: 365 * 2, // 2 years
}
export const MAX_AVAILABLE_RANGE_BY_GRAN = {
  [DATE_GRAN.hourly]: date => dayjs(date).add(1, 'week').diff(dayjs(date), 'days'), // week
  [DATE_GRAN.daily]: date => dayjs(date).add(6, 'month').diff(dayjs(date), 'days'),// 6 month
  [DATE_GRAN.weekly]: date => dayjs(date).add(2, 'year').diff(dayjs(date), 'days'), // 2 years
}

export const TRIMMINGS_COLOR_MAP = {
  Trimmings: COLORS.accentBlue,
  Preventable: COLORS.brandYellow,
}
