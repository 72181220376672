import {trimQuery} from "../data/trimQuery";

export const getEventImages = (event, selectedImage) => {
  const backupIndex = event.backup_image_url.findIndex(url => url === selectedImage);
  if (backupIndex === -1) {
    return {}
  }
  return {
    imageUrl: trimQuery(selectedImage),
    backupImageUrl: event.backup_image_url.toSpliced(backupIndex, 1, event.image_url).map(trimQuery),
  }
}